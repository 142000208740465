<template>
  <div class="pageFooter">
    <div class="footerContainer flex paddingBox">
      <div class="flex-col flex-2">
        <a href="/">
          <img
            src="../../assets/images/logo.png"
            class="logo mb-2"
            style="width: 40px; height: 40px; opacity: 1"
          />
        </a>
        <p class="joinP tBg">
          {{ $t("homeFooter.joinUs") }}
        </p>
      </div>
      <div class="flex-col flex-1 tBg paddingBox">
        <div class="lineTitle">NYSEIMF</div>
        <div class="lineText" @click="jumpPage('/market')">
          <i class="el-icon-data-line"></i>
          <span>{{ $t("menu.quotation") }}</span>
        </div>
        <div class="lineText" @click="jumpPage('/marketContract')">
          <i class="el-icon-tickets"></i>
          <span>{{ $t("menu.contract") }}</span>
        </div>
        <div class="lineText" @click="jumpPage('/assetsPage')">
          <i class="el-icon-s-finance"></i>
          <span>{{ $t("menu.assets") }}</span>
        </div>
      </div>
      <div class="flex-col flex-1 tBg paddingBox">
        <div class="lineTitle">
          {{ $t("menu.transactionType") }}
        </div>
        <div class="lineText" @click="jumpPage('/marketDetail')">
          <i class="el-icon-files"></i>
          <span>{{ $t("menu.transaction") }}</span>
        </div>
        <!-- <div class="lineText" @click="jumpPage('/marketCurrency')">
          <i class="el-icon-notebook-1"></i>
          <span>{{ $t("menu.currencytransaction") }}</span>
        </div>
        <div class="lineText">
          <i class="el-icon-notebook-2"></i>
          <span>{{ $t("menu.fiatTransaction") }}</span>
        </div> -->
      </div>
      <div class="flex-col flex-1 tBg paddingBox">
        <div class="lineTitle">
          {{ $t("headerText.liaison") }}
        </div>
        <div class="lineText" @click="jumpPage('/innerPage/heloCenter?id=19')">
          <i class="el-icon-warning-outline"></i>
          <span>{{ $t("headerText.aboutSelf") }}</span>
        </div>
        <div class="lineText" @click="jumpPage('/innerPage/heloCenter?id=35')">
          <i class="el-icon-coordinate"></i>
          <span>{{ $t("headerText.helpCenter") }}</span>
        </div>
        <div class="lineText" @click="jumpKf">
          <i class="el-icon-chat-dot-round"></i>
          <span>{{ $t("headerText.customerService") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { getkf } from "@/api/user";
import { useRouter } from "@/utils/vueApi";
const router = useRouter();
const jumpPage = (url) => {
  router.push(url);
}
const urlList = ref([]);
const jumpKf = () => {
  if(urlList.value.length > 0) {
    window.open(urlList.value[0], "_blank");
  }
}
onMounted(async () => {
  const { message } = await getkf();
  urlList.value = message;
});
</script>
<style scoped lang="scss">
.pageFooter {
  margin-top: 10px;
  color: white;
  background-color: #252525;
  padding: 20px;
  height: 280px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  .footerContainer {
    width: 1140px;
    padding: 40px;
    .paddingBox {
      padding: 0 15px;
    }
    .tBg {
      opacity: 0.8;
    }
    .joinP {
      line-height: 32px;
      font-size: 14px;
    }
    .lineTitle {
      margin-bottom: 10px;
      font-size: 14px;
    }
    .lineText {
      line-height: 32px;
      cursor: pointer;

      span {
        margin-left: 5px;
        &:hover {
          color: #5670f6;
          font-weight: bold;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
