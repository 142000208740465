<template>
  <div class="home">
    <div class="bannerBox">
      <el-carousel height="450px">
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <img :src="item.src" alt="" style="width: 100%; height: 100%" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="infoBox">
      <swiper :options="swiperOptions">
        <swiper-slide
          v-for="(item, index) in infoData"
          :key="index"
          class="cur-p"
        >
          <div
            class="swiper-slide"
            @click="jumpPage(`/marketDetail?id=${item.id}&type=1`)"
          >
            <img :src="item.logo" style="width: 80px; height: 80px" />
            <div class="infoContent flex-col">
              <span class="fs-bold"
                >{{ item.currency_name }}/{{ item.legal_name }}</span
              >
              <span
                :class="{ red: item.change > 0, green: item.change < 0 }"
                class="fs-bold"
                >{{ item.now_price }}</span
              >
              <span :class="{ red: item.change > 0, green: item.change < 0 }"
                >{{ item.change }}%</span
              >
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="mainContent">
      <h5 class="mainTitle">
        {{ $t("menu.quotation") }}
      </h5>
      <div class="mainBox flex">
        <div class="mainBoxItem">
          <rankings
            :title="$t('rankText.priceIncrease')"
            :data="infoData"
            pageType="1"
          ></rankings>
        </div>
        <div class="mainBoxItem">
          <rankings
            :title="$t('rankText.transactionAmount')"
            :data="infoData"
            pageType="2"
          ></rankings>
        </div>
      </div>
    </div>
    <pageFooter></pageFooter>
  </div>
</template>
<script setup>
import pageFooter from "@/components/home/pageFooter.vue";
import { onMounted, onUnmounted, ref } from "vue";
import { getQuotation_new } from "@/api/market";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.min.css";
import rankings from "@/components/home/rankings.vue";
import { useRouter } from "@/utils/vueApi";
import { useLang } from "@/hooks/useLang";
const { lang } = useLang();
const router = useRouter();
const bannerList = ref([
  {
    src: require("../assets/images/common/banner.png"),
  },
]);
const swiperOptions = ref({
  slidesPerView: 4,
  // 其他Swiper选项
});
const jumpPage = (url) => {
  router.push(url);
};
// 数据
const infoData = ref([]);
// 获取页面数据
const getNumData = async () => {
  const { message } = await getQuotation_new({ lang: lang.value });
  infoData.value = message[0].quotation;
};
let timer;
const initData = () => {
  timer = setInterval(() => {
    getNumData();
  }, 2000)
};
onMounted(() => {
  initData();
});
onUnmounted(() => {
  if(timer) {
    clearInterval(timer)
  }
})
</script>

<style lang="scss" scope>
.home {
  width: 100%;
  .bannerBox {
    margin-top: 20px;
    margin: 10px;
    padding: 10px;
    box-sizing: border-box;
    background-color: $theme-color;
  }
  .infoBox {
    padding: 20px 0;
    margin: 20px;
    background: #fff;
    box-shadow: $box-shadow;
    border-radius: 10px;
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .infoContent {
      margin-left: 20px;
      align-items: flex-start;
      span {
        font-size: 14px;
      }
      span:nth-child(1) {
        margin-bottom: 5px;
      }
      span:nth-child(2) {
        font-size: 24px;
      }
    }
  }
  .mainContent {
    .mainTitle {
      font-weight: bold;
      padding: 20px 0px 0px 20px;
      font-size: 24px;
    }
    .mainBox {
      width: 100%;
      padding: 10px 20px;
      box-sizing: border-box;
      .mainBoxItem {
        width: 10px;
        flex: 1;
        &:nth-child(1) {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
