<template>
  <div class="rankings">
    <div class="rankTitle">{{ title }}</div>
    <div class="rankBox">
      <el-table :data="data" :empty-text="$t('rankText.emptyText')" style="width: 100%" size="small" @row-click="rowClick" :cell-style="cellStyle">
        <el-table-column prop="name" :label="$t('rankText.name')">
          <template #default="scope">
            <img
              :src="scope.row.logo"
              style="width: 30px; height: 30px; vertical-align: middle"
            />
            <span> {{ scope.row.currency_name }}/{{ scope.row.legal_name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="price" :label="$t('rankText.nowPrice')">
          <template #default="scope">
            <span
              :class="[
                pageType == '1' ? (scope.row.change > 0 ? 'red' : 'green') : '',
              ]"
              >{{ scope.row.now_price }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="rate"
          :label="
            pageType == '1'
              ? $t('rankText.priceIncrease')
              : $t('rankText.transactionAmount')
          "
        >
          <template #default="scope">
            <div class="rightItem">
              <template v-if="pageType == '1'">
                <span :class="scope.row.change > 0 ? 'redBg' : 'greenBg'"
                  >{{ scope.row.change }}%</span
                >
              </template>
              <template v-else>
                <span class="blueBg">{{ scope.row.volume }}</span>
              </template>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "@/utils/vueApi";
const router = useRouter();
const props = defineProps({
  pageType: {
    // 1 涨幅   2 成交额
    type: String,
  },
  title: {
    type: String,
  },
  data: {
    type: Array,
  },
});
const cellStyle = () => {
  return 'cursor: pointer'
}
const rowClick = (row) => {
  console.log(row)
  jumpPage(`/marketDetail?id=${row.id}&type=1`)
}
const jumpPage = (url) => {
  console.log(url);
  router.push(url);
};
onMounted(() => {});
</script>
<style scoped lang="scss">
.rankings {
  box-shadow: 0 2px 10px rgb(142 131 131 / 30%);
  .rankTitle {
    font-size: 18px;
    border-radius: 10px 10px 0px 0px;
    padding: 20px;
    background-color: #5670f6;
    color: white;
  }
  .rankBox {
    background-color: $section-color;
    border-radius: 10px;
    padding: 40px;
    box-sizing: border-box;
    .rightItem span {
      display: inline-flex;
      width: 100%;
      max-width: 100px;
      box-sizing: border-box;
      height: 32px;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
    }
  }
}
</style>
